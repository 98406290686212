import { useForm } from "react-hook-form";
import ThreeDotsVertical from "../../../../assets/svg/ThreeDotsVertical";
import { useSelector } from "react-redux";
import { JobSkills } from "../../../../utils/constants";

const ProgressBar = ({ value }) => {
  return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]?.map((item) => {
    return (
      <div
        key={item}
        className={`w-4 h-3 ${
          value >= item + 1 ? "bg-[#EFBF04]" : "bg-[#ececec]"
        }`}
      />
    );
  });
};

const SkillInput = ({ data, index }) => {
  const { watch } = useForm({
    defaultValues: {
      ...data,
      lastUsed: "current",
    },
  });

  if (data) {
    return (
      <div
        key={index}
        className={`flex items-center py-4 px-4 ${
          index !== 0 ? "border-t" : ""
        }`}
      >
        <label
          style={{ width: "25%" }}
          className="flex text-xs font-medium text-primary"
        >
          {data?.title}
        </label>
        <div
          style={{ width: "25%" }}
          className="flex text-xs font-medium text-primary gap-1.5"
        >
          <ProgressBar value={data?.rating} />
        </div>
        <label
          style={{ width: "25%" }}
          className="flex text-xs font-medium text-primary justify-center"
        >
          {data?.years}
        </label>
        <label
          style={{ width: "20%" }}
          className="flex text-xs font-medium text-primary"
        >
          {JobSkills.find((j) => j.value === data?.lastUsed)?.label}
        </label>
        <div
          style={{ width: "5%" }}
          className="flex text-xs font-medium text-primary cursor-pointer justify-center"
        >
          <ThreeDotsVertical className={"w-4 h-4"} fill={"#6f6f6f"} />
        </div>
      </div>
    );
  }
};

const SkillListSection = ({ data, index, editSkill }) => {
  const { jobDetail } = useSelector((state) => state?.job);

  return (
    <div className="flex flex-col mt-8">
      {jobDetail?.skills?.length === 0 ? (
        <p className="flex justify-center mt-24">No skills were added.</p>
      ) : (
        <>
          <div className="flex items-center pb-3 px-4">
            <label
              style={{ width: "25%" }}
              className="flex font-semibold text-sm text-primary"
            >
              Skill
            </label>
            <label
              style={{ width: "25%" }}
              className="flex font-semibold text-sm text-primary"
            >
              Level out of 10
            </label>
            <label
              style={{ width: "25%" }}
              className="flex font-semibold text-sm text-primary justify-center"
            >
              Years of experience
            </label>
            <label
              style={{ width: "20%" }}
              className="flex font-semibold text-sm text-primary"
            >
              Last used
            </label>
            <div className="w-[5%]" />
          </div>

          {jobDetail?.skills?.map((skill, index) => (
            <SkillInput
              key={index}
              data={skill}
              index={index}
              editSkill={editSkill}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default SkillListSection;
