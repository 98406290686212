import "./App.css";
import { RouterProvider } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useRoutes from "./router";
import {
  refreshToken,
  setAuthLoading,
  setUserData,
} from "./store/actions/auth.action";
import { useEffect, useRef, useState } from "react";
import { getCandidateDetails } from "./endpoints/candidate.service";

function IRouter() {
  const { router } = useRoutes();
  return <RouterProvider router={router} />;
}

function App() {
  const dispatch = useDispatch();
  const { logged_in, loading, authenticated, user } = useSelector(
    (state) => state.auth
  );
  const [initialized, setInitialized] = useState(false);
  const initiating = useRef(false);

  const loadInitialData = async (payload) => {
    // load initial data here
    if (payload?.userType === 3) {
      const timer = setTimeout(async () => {
        const { success, data } = await getCandidateDetails();
        if (success) {
          dispatch(setUserData(data));
        }
        clearTimeout(timer);
      }, 200);
    }
  };

  const initiateApp = async () => {
    initiating.current = true;
    const token = localStorage.getItem("refreshToken");

    //redirect to app
    if (!logged_in && token && !authenticated && initiating.current) {
      refreshToken(token)(dispatch)
        .then((data) => {
          if (data) {
            loadInitialData(data?.user);
            setTimeout(() => {
              setInitialized(true);
            }, 100);
          } else {
            window.location.replace("/");
          }
        })
        .finally(() => {
          initiating.current = false;
        });
    }

    //redirect to auth pages
    else {
      dispatch(setAuthLoading(false));
      // loadInitialData();
      setTimeout(() => {
        setInitialized(true);
      }, 100);
      initiating.current = false;
    }
  };

  useEffect(() => {
    if (!initiating.current) {
      initiateApp();
    }
  }, []);

  return !loading && initialized ? <IRouter /> : "Loading";
}

export default App;
