import {
  Controller,
} from "react-hook-form";
import CustomInput from "../../../../../components/CustomInput/CustomInput";
import QuillEditor from "../../../../../components/CreateQuestion/Question/QuillEditor";
import moment from "moment";
import { AddEditExperienceProps } from "./experienceBlock.types";

const AddEditExperience: React.FC<AddEditExperienceProps> = ({
  control,
  fields,
  errors,
  setValue,
}) => {
  return (
    <div className="w-full">
      <div className="flex flex-col gap-4 mb-3">
        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            checked={fields?.isCurrent}
            className="accent-[#EFBF04] size-4 rounded text-emerald-500"
            onChange={(e) => setValue("isCurrent", e?.target?.checked)}
          />
          <label className="text-primary text-xs font-medium">
            Currently working for this role
          </label>
        </div>
        <div className="grid grid-cols-2 gap-5">
          <div className="flex flex-col gap-3">
            <CustomInput
              type="date"
              label="Start Date"
              name="startDate"
              rules={{ required: "This field is required." }}
              control={control}
              errors={errors}
            />
            <CustomInput
              type="text"
              label={"Job Title"}
              name={"jobTitle"}
              placeholder={"Enter your Job Title"}
              rules={{ required: "This field is required." }}
              control={control}
              errors={errors}
            />
            <CustomInput
              label="Location"
              name="location"
              rules={{
                required: "This field is required.",
              }}
              placeholder={"Ex: London"}
              control={control}
              errors={errors}
            />
          </div>
          <div className="flex flex-col gap-3">
            <div
              className={`${fields?.isCurrent ? "opacity-20" : "opacity-100"}`}
            >
              <CustomInput
                type="date"
                label={"End Date"}
                name={"endDate"}
                rules={{
                  required: fields?.isCurrent
                    ? false
                    : "This field is required.",
                }}
                control={control}
                errors={errors}
              />
            </div>

            <CustomInput
              type="text"
              label={"Company Name"}
              name={"companyName"}
              placeholder={"Enter your Company Name"}
              rules={{ required: "This field is required." }}
              control={control}
              errors={errors}
            />
          </div>
        </div>
        <div className="mr-4">
          <Controller
            name={"jobDescription"}
            control={control}
            rules={{
              required: "This field is required.",
            }}
            render={({ field }) => {
              return (
                <div className="flex flex-col gap-1">
                  <span className="font-montserrat text-primary font-medium text-xs mb-2">
                    Job Description{" "}
                    <span className="font-montserrat text-primary font-medium text-xs text-red-500">
                      *
                    </span>
                  </span>
                  <QuillEditor
                    onChange={field.onChange}
                    value={field.value}
                    label=""
                    required={true}
                  />
                  {errors["jobDescription"]?.message && (
                    <span className="whitespace-pre text-xs mt-2 text-orange-700">
                      {errors["jobDescription"]?.message}
                    </span>
                  )}
                </div>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AddEditExperience;
