import { Controller, useForm } from "react-hook-form";
import SaveIcon from "../../../assets/svg/SaveIcon";
import TrashIcon from "../../../assets/svg/TrashIcon";
import CustomInput from "../../../components/CustomInput/CustomInput";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import CustomSlider from "../../../components/CustomSlider/CustomSlider";
import { useCallback, useEffect } from "react";
import { useSnackbar } from "notistack";
import EditIcon from "../../../assets/svg/EditIcon";
import { useDispatch, useSelector } from "react-redux";
import { getSkills } from "../../../endpoints/experience.service";
import { skillsUsed } from "../../../utils/constants";
import { setUserData } from "../../../store/actions/auth.action";
import { deleteSkill } from "../../../endpoints/candidate.service";

export const SkillInput = ({
  data,
  addUpdateSkill,
  index,
  deleteSkill,
  editSkill,
  onEditSkill,
}) => {
  const {
    watch,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      ...data,
      lastUsed: data?.lastUsed ?? "current",
    },
  });
  const field = watch();

  const onSave = () => {
    addUpdateSkill(field, index);
  };

  if (data?.isSaved) {
    return (
      <>
        <div className="flex items-center my-4">
          <label
            style={{ width: "20%" }}
            className="flex font-medium text-xs text-primary"
          >
            {data?.title}
          </label>
          <label
            style={{ width: "25%" }}
            className="flex font-medium text-xs text-primary"
          >
            <ProgressBar value={data?.rating} total={10} />
          </label>
          <label
            style={{ width: "40%" }}
            className="flex font-medium text-xs text-primary justify-center"
          >
            {data?.years}
          </label>
          <label
            style={{ width: "15%" }}
            className="flex font-medium text-xs text-primary"
          >
            {Object.keys(skillsUsed).find(
              (key) => skillsUsed[key] === data?.lastUsed
            )}
          </label>
          {data?.isEdit && (
            <div className="flex w-[5%] mr-5 items-end gap-3">
              <div
                className="cursor-pointer"
                onClick={() => onEditSkill(index)}
              >
                <EditIcon className={"w-4 h-4"} />
              </div>
              <div
                className="cursor-pointer"
                onClick={() => deleteSkill(index)}
              >
                <TrashIcon className={"w-4 h-4"} />
              </div>
            </div>
          )}
        </div>
        <div className="w-full border-t border-[#DDDDDDDD] my-2"></div>
      </>
    );
  }
  return (
    <div className="flex items-center px-4 py-3 bg-light-white-700 rounded-lg mb-5">
      <div
        style={{ width: "25%" }}
        className="flex flex-col  text-sm text-primary"
      >
        <Controller
          control={control}
          name="skill"
          rules={{
            required: "This field is required.",
          }}
          render={({ field: { value, onChange } }) => (
            <input
              className="font-normal rounded-md bg-white px-3 py-2 text-xs w-[85%]"
              placeholder="Javascript"
              value={value}
              onChange={(c) => onChange(c.target.value)}
            />
          )}
        />
        {errors?.skill?.message && (
          <span className="whitespace-pre text-xs mt-2 text-orange-700">
            {errors?.skill?.message}
          </span>
        )}
      </div>
      <div
        style={{ width: "25%" }}
        className="flex flex-col  text-sm text-primary"
      >
        <Controller
          control={control}
          name="level"
          rules={{
            required: "This field is required.",
          }}
          render={({ field: { value, onChange } }) => (
            <CustomSlider
              step={1}
              min={0}
              max={10}
              fillerClass={`h-[5px]`}
              defaultValue={5}
              value={value}
              labelStyle={`text-xs`}
              onChange={onChange}
            />
          )}
        />
        {errors?.level?.message && (
          <span className="whitespace-pre text-xs mt-2 text-orange-700">
            {errors?.level?.message}
          </span>
        )}
      </div>
      <label
        style={{ width: "25%" }}
        className="flex flex-col font-semibold text-sm text-primary items-center justify-center"
      >
        <Controller
          control={control}
          name="experience"
          rules={{
            required: "This field is required.",
          }}
          render={({ field: { value, onChange } }) => (
            <input
              className="font-normal rounded-md bg-white px-3 py-2 text-xs w-1/2"
              placeholder="9"
              value={value}
              onChange={(c) => onChange(c.target.value)}
            />
          )}
        />
        {errors?.experience?.message && (
          <span className="whitespace-pre text-xs mt-2 text-orange-700">
            {errors?.experience?.message}
          </span>
        )}
      </label>
      <div style={{ width: "20%" }} className="flex text-sm text-primary">
        <div className="w-full">
          <CustomInput
            name="lastUsed"
            control={control}
            errors={errors}
            type="dropdown"
            dropdownList={["current", "this year", "last year", "2+ years"]}
            rules={{
              required: "This field is required.",
            }}
          />
        </div>
      </div>
      <div className="flex flex-col w-[5%] items-end gap-3">
        <div className="cursor-pointer" onClick={handleSubmit(onSave)}>
          <SaveIcon className={"w-4 h-4"} />
        </div>
        <div className="cursor-pointer" onClick={() => deleteSkill(index)}>
          <TrashIcon className={"w-4 h-4"} />
        </div>
      </div>
    </div>
  );
};

const Skills = ({ isEditing }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const {
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      skills: [],
    },
  });
  const field = watch();

  // useEffect(() => {
  //   if (user?.skills) {
  //     setValue(
  //       "skills",
  //       user?.skills?.map((item) => {
  //         return { ...item, isSaved: true };
  //       })
  //     );
  //   }
  // }, [user]);

  useEffect(() => {
    if (isEditing) {
      const tempSkills = field?.skills?.map((x) => ({
        ...x,
      }));
      setValue("skills", [...tempSkills]);
      return;
    } else {
      const tempSkills = field?.skills?.map((x) => ({
        ...x,
      }));
      setValue("skills", [...tempSkills]);
    }
  }, [isEditing]);

  const onEditSkill = (index) => {
    let tempSkills = field.skills;
    tempSkills[index].isSaved = false;
    setValue("skills", [...tempSkills]);
  };

  const addNewskill = () => {
    setValue("skills", [{ isSaved: false, isEdit: true }, ...field?.skills]);
  };

  const addUpdateSkill = useCallback(
    async (data, index) => {
      let tempSkills = field.skills;
      tempSkills[index].isSaved = true;
      setValue("skills", [...tempSkills]);
      try {
        let response;
        const lastUsed = JobSkills.find((x) => x.label === data?.lastUsed);
        if (data?._id) {
          response = await updateSkill({
            id: data?._id,
            title: data?.skill,
            rating: data?.level,
            years: data?.experience,
            lastUsed: lastUsed?.value,
          });
        } else {
          response = await addSkill({
            title: data?.skill,
            rating: data?.level,
            years: data?.experience,
            lastUsed: lastUsed?.value,
            jobId: createdDraftJob?._id,
            type: 2,
            userType: 2,
          });
        }

        if (response?.success) {
          let newSkills = field.skills;
          newSkills[index] = {
            ...data,
            ...response?.data,
            isEdit: false,
            isSaved: true,
          };
          setValue("skills", [...newSkills]);
        }
      } catch (error) {
        enqueueSnackbar(error, { variant: "error" });
      }
    },
    [field]
  );

  const deleteSkillPress = async (index) => {
    try {
      const skill = field.skills[index];
      if (skill?.isSaved === false) {
        let n = field?.skills;
        n.splice(index, 1);
        setValue("skills", [...n]);
        return;
      }
      const data = await deleteSkill(skill?._id);
      if (data?.success) {
        let n = field?.skills;
        n.splice(index, 1);
        setValue("skills", [...n]);
        return;
      }
    } catch (error) {
      enqueueSnackbar(error, { variant: "error" });
    }
  };

  const editSkill = useCallback(
    async (index) => {
      let newSkills = field.skills;
      newSkills[index] = {
        ...newSkills[index],
        isEdit: true,
        isSaved: false,
      };
      setValue("skills", [...newSkills]);
    },
    [field]
  );

  const fetchUserSkills = useCallback(async () => {
    try {
      const { success, data } = await getSkills();
      if (success) {
        dispatch(setUserData({ skills: data }));
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    fetchUserSkills();
  }, []);

  return (
    <div>
      <div className="h-full flex flex-1 flex-col pt-7 overflow-y-scroll no-scrollbar">
        {user?.skills?.length > 0 && (
          <div className="flex items-center pb-3">
            <label
              style={{ width: "20%" }}
              className="flex font-bold text-sm text-primary"
            >
              Skill
            </label>
            <label
              style={{ width: "25%" }}
              className="flex font-bold text-sm text-primary"
            >
              Level out of 10
            </label>
            <label
              style={{ width: "40%" }}
              className="flex font-bold text-sm text-primary justify-center"
            >
              Years of experience
            </label>
            <label
              style={{ width: "15%" }}
              className="flex font-bold text-sm text-primary"
            >
              Last used
            </label>
            <label
              style={{ width: "8%" }}
              className="flex font-bold text-sm text-primary"
            />
          </div>
        )}
        {user?.skills?.map((skill, index) => (
          <SkillInput
            data={{ ...skill, isSaved: true }}
            key={index + new Date()}
            addUpdateSkill={addUpdateSkill}
            index={index}
            deleteSkill={deleteSkillPress}
            editSkill={editSkill}
            onEditSkill={onEditSkill}
          />
        ))}
      </div>
    </div>
  );
};

export default Skills;
