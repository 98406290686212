import React from "react";
import { useForm, Controller } from "react-hook-form";
import { SkillInputProps } from "./skillInput.types";
import TrashIcon from "../../../../assets/svg/TrashIcon";
import CustomInput from "../../../../components/CustomInput/CustomInput";
import SaveIcon from "../../../../assets/svg/SaveIcon";
import CustomSlider from "../../../../components/CustomSlider/CustomSlider";

export const SkillInput: React.FC<SkillInputProps> = ({
  data,
  addUpdateSkill,
  index,
  deleteSkill,
}) => {
  const {
    watch,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      ...data,
      lastUsed: data?.lastUsed ?? "current",
    },
  });
  const field = watch();

  const onSave = () => {
    addUpdateSkill(field, index);
  };

  return (
    <div className="flex items-center px-4 py-3 bg-light-white-700 rounded-lg mb-5">
      <div
        style={{ width: "25%" }}
        className="flex flex-col  text-sm text-primary"
      >
        <Controller
          control={control}
          name="title"
          rules={{
            required: "This field is required.",
          }}
          render={({ field: { value, onChange } }) => (
            <input
              className="font-normal rounded-md bg-white px-3 py-2 text-xs w-[85%]"
              placeholder="Javascript"
              value={value}
              onChange={(c) => onChange(c.target.value)}
            />
          )}
        />
        {errors?.title?.message && (
          <span className="whitespace-pre text-xs mt-2 text-orange-700">
            {errors?.title?.message}
          </span>
        )}
      </div>
      <div
        style={{ width: "25%" }}
        className="flex flex-col  text-sm text-primary"
      >
        <Controller
          control={control}
          name="rating"
          rules={{
            required: "This field is required.",
          }}
          render={({ field: { value, onChange } }) => (
            <CustomSlider
              step={1}
              min={0}
              max={10}
              fillerClass={`h-[5px]`}
              baseClass
              className
              label
              value={value}
              labelStyle={`text-xs`}
              onChange={onChange}
            />
          )}
        />
        {errors?.rating?.message && (
          <span className="whitespace-pre text-xs mt-2 text-orange-700">
            {errors?.rating?.message}
          </span>
        )}
      </div>
      <label
        style={{ width: "25%" }}
        className="flex flex-col font-semibold text-sm text-primary items-center justify-center"
      >
        <Controller
          control={control}
          name="years"
          rules={{
            required: "This field is required.",
          }}
          render={({ field: { value, onChange } }) => (
            <input
              className="font-normal rounded-md bg-white px-3 py-2 text-xs w-1/2"
              placeholder="9"
              value={value}
              onChange={(c) => onChange(c.target.value)}
            />
          )}
        />
        {errors?.years?.message && (
          <span className="whitespace-pre text-xs mt-2 text-orange-700">
            {errors?.years?.message}
          </span>
        )}
      </label>
      <div style={{ width: "20%" }} className="flex text-sm text-primary">
        <div className="w-full">
          <CustomInput
            name="lastUsed"
            control={control}
            errors={errors}
            type="dropdown"
            dropdownList={["current", "this year", "last year", "2+ years"]}
            rules={{
              required: "This field is required.",
            }}
          />
        </div>
      </div>
      <div className="flex flex-col w-[5%] items-end gap-3">
        <div className="cursor-pointer" onClick={handleSubmit(onSave)}>
          <SaveIcon fill="" className={"w-4 h-4"} />
        </div>
        <div className="cursor-pointer" onClick={() => deleteSkill(index)}>
          <TrashIcon fill="" className={"w-4 h-4"} />
        </div>
      </div>
    </div>
  );
};
