import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import About from "./Components/About";
import Experience from "./Components/experience/experience";
import Skills from "./Components/Skills";
import ReferIcon from "../../assets/svg/ReferIcon";
import TrackingIcon from "../../assets/svg/TrackingIcon";
import LocationIconOutline from "../../assets/svg/LocationIconOutline";
import ShareIconOutline from "../../assets/svg/ShareIconOutline";
import LikeIconLeft from "../../assets/svg/LikeIconLeft";
import EditIcon from "../../assets/svg/EditIcon";
import ContactDetails from "./Components/ContactDetails";
import CheckmarkIcon from "../../assets/svg/CheckmarkIcon";
import { useSelector } from "react-redux";
import {
  getCandidateDetails,
  getSkills,
  updateCandidate,
} from "../../endpoints/candidate.service";
import { useDispatch } from "react-redux";
import { setUserData } from "../../store/actions/auth.action";
import { candidateStatus } from "../../utils/constants";
import { useSnackbar } from "notistack";
import Education from "./Components/Education";
import { getExperienceList } from "../../endpoints/experience.service";
import LoadingAnimation from "../../assets/svg/LoadingAnimation";
import { colors } from "../../utils/theme";
import ChevronDownFilled from "../../assets/svg/ChevronDownFilled";
import { Controller, useForm } from "react-hook-form";
import CustomInput from "../../components/CustomInput/CustomInput";
import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { profileImage, uploadUrl } from "../../endpoints/images.service";

const availabilityStatus = Object.entries(candidateStatus).map(
  ([key, value]) => ({
    title: value,
    id: key,
  })
);

const tabs = [
  "About",
  "Experience",
  "Skills",
  "Education & Certifications",
  "Contact Details",
];

const RenderBody = forwardRef(
  (
    { activeTab, isEditing, setEditingTab, handleSave, handleEditClose },
    ref
  ) => {
    switch (activeTab) {
      case 0:
        return (
          <About
            ref={ref}
            isEditing={isEditing}
            setEditingTab={setEditingTab}
            handleSave={handleSave}
          />
        );
      case 1:
        return (
          <Experience
            ref={ref}
            isEditing={isEditing}
            setEditingTab={setEditingTab}
            handleEditClose={handleEditClose}
          />
        );
      case 2:
        return <Skills isEditing={isEditing} setEditingTab={setEditingTab} />;
      case 3:
        return (
          <Education
            ref={ref}
            isEditing={isEditing}
            setEditingTab={setEditingTab}
          />
        );
      case 4:
        return (
          <ContactDetails
            ref={ref}
            isEditing={isEditing}
            setEditingTab={setEditingTab}
            handleSave={handleSave}
          />
        );
      default:
        return <div />;
    }
  }
);

const Profile = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const formRef = useRef();
  const cropperRef = useRef(null);
  const filePickerRef = useRef();
  const { user } = useSelector((state) => state.auth);
  const [activeTab, setActiveTab] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [editingTab, setEditingTab] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      jobTitle: user?.jobTitle,
      status: availabilityStatus?.find((x) => x.id === user?.status)?.id ?? "",
      location: user?.location,
      image: undefined,
      openProfileModel: false,
    },
  });

  const fields = watch();

  const handleTabClick = (index) => {
    setIsEditing(false);
    setEditingTab(undefined);
    setActiveTab(index);
  };

  const handleNext = () => {
    if (isEditing && (activeTab === 0 || activeTab === 4)) {
      formRef?.current();
    }
  };

  const handleSave = async (formData, enable) => {
    if (activeTab === 0 || activeTab === 4 || enable) {
      try {
        setIsLoading(true);
        const { success, data } = await updateCandidate(formData);
        if (success) {
          dispatch(setUserData(data));
          setIsEditing(false);
          setEditingTab(activeTab);
        }
      } catch (error) {
        enqueueSnackbar(error, {
          variant: "error",
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (user) {
      setValue("jobTitle", user?.jobTitle);
      setValue("location", user?.location);
      setValue(
        "status",
        availabilityStatus?.find((x) => x.id === user?.status)?.id ?? ""
      );
    }
  }, [user]);

  const fetchCandidateDetails = useCallback(async () => {
    try {
      const { success, data } = await getCandidateDetails();
      if (success) {
        dispatch(setUserData(data));
      }
    } catch (error) {
      throw error?.message;
    }
  }, []);

  const fetchExperienceList = useCallback(async () => {
    try {
      const { success, data } = await getExperienceList();
      if (success) {
        dispatch(setUserData({ experience: data }));
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const fetchUserSkills = useCallback(async () => {
    try {
      const { success, data } = await getSkills();
      if (success) {
        dispatch(setUserData({ skills: data }));
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    fetchCandidateDetails();
    fetchExperienceList();
    fetchUserSkills();
  }, []);

  const closeModal = () => {
    setValue("openProfileModel", false);
    setValue("image", undefined);
    setValue("file", undefined);
    setValue("profileImage", undefined);
  };

  const handleFileChange = async (e) => {
    try {
      const file = e.target.files[0];
      setValue("file", file, { shouldDirty: true });
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setValue("image", reader.result, { shouldDirty: true });
        };
        reader.readAsDataURL(file);
      }
    } catch (error) {
      console.error("Error during file upload or API calls:", error);
    }
  };

  const getCroppedImage = (e) => {
    e.preventDefault();

    if (cropperRef.current) {
      const cropperInstance = cropperRef.current.cropper;
      if (cropperInstance) {
        const croppedDataUrl = cropperInstance.getCroppedCanvas().toDataURL();
        setValue("profileImage", croppedDataUrl);
        setValue("image", undefined);
      } else {
        console.error("Cropper instance is not found");
      }
    } else {
      console.error("cropperRef.current is not initialized");
    }
  };

  const updateProfile = async (payload) => {
    try {
      setIsLoading(true);
      if (payload?.profileImage) {
        const { data } = await profileImage();
        if (!data?.data || !data?.data.uploadURL) {
          throw new Error("Failed to get upload URL");
        }
        const formData = new FormData();
        const base64Data = payload?.profileImage?.split(";base64,").pop();
        const byteArray = Uint8Array.from(atob(base64Data), (c) =>
          c.charCodeAt(0)
        );
        const blob = new Blob([byteArray], { type: payload?.file?.type });
        formData.append("file", blob, payload?.file?.name);
        const fileResponse = await uploadUrl(data?.data.uploadURL, formData);
        delete payload["file"];
        delete payload["profileImage"];
        delete payload["image"];
        const picURl = fileResponse?.variants?.find(
          (x) => x.split("/").slice(-1)[0] === "public"
        );
        payload["profilePic"] = picURl;
      }
      const { success, data: userApiData } = await updateCandidate(payload);
      if (success) {
        dispatch(setUserData(userApiData));
        setValue("profileImage", undefined);
        setValue("image", undefined);
        setValue("openProfileModel", false);
      }
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
      });
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex bg-white flex-col flex-1 h-full overflow-hidden w-full shadow-[2px_2px_12px_0px_rgba(180,180,180,0.25)] rounded-lg p-4">
      {/* Top container */}
      <div className="flex items-center bg-[#FAFAFA] py-3.5 pl-3.5 pr-[18px] rounded-xl border border-[#F7F7F7] mb-1 relative">
        {/* Image container */}
        <div className="absolute top-0 flex rounded-full h-40 w-40 bg-[#E1E0E0] items-center justify-center">
          {user?.profilePic ? (
            <img
              src={user?.profilePic}
              className="rounded-full h-40 w-40 object-fill"
            />
          ) : (
            <span className="font-montserrat text-white font-semibold text-6xl uppercase">
              {user?.firstName?.charAt(0)}
              {user?.lastName?.charAt(0)}
            </span>
          )}
          <div
            className="flex items-center absolute top-3 right-2 bg-white justify-center rounded-full border self-start p-1"
            onClick={() => {
              setValue("openProfileModel", true);
            }}
          >
            <EditIcon className={"w-5 h-5"} fill="#4d4d4d" />
          </div>
        </div>

        <div className="flex justify-between flex-1 gap-1 pl-44">
          <div className="flex gap-4 relative">
            <div className="flex flex-col gap-2">
              <span className="font-montserrat text-primary font-semibold text-2xl border-b border-primary w-max pb-1">
                {user?.firstName} {user?.lastName}
              </span>
              <div className="flex items-center gap-0.5">
                <span className="font-montserrat text-primary font-medium text-xs max-[900px]:w-min">
                  {user?.jobTitle}
                </span>
              </div>
              <div className="flex items-center gap-0.5">
                <LocationIconOutline className={"w-3.5 h-3.5"} />
                <span className="font-montserrat text-light-black font-medium text-[9px] max-[900px]:w-min">
                  {user?.location}
                </span>
              </div>
            </div>
          </div>
          <div className="relative flex items-center self-start gap-2">
            <div className="w-[8px] h-[8px] bg-[#23BD33] rounded-full animate-pulse" />
            <select
              value={user?.status}
              onChange={(e) => handleSave({ status: e?.target?.value }, true)}
              className="appearance-none font-montserrat font-medium text-[10px] text-light-black bg-transparent border-none focus:outline-none cursor-pointer"
            >
              {Object?.values(candidateStatus)?.map((x) => (
                <option value={x}>{x?.trim()}</option>
              ))}
            </select>
            <ChevronDownFilled
              className="w-3.5 h-3.5 pointer-events-none"
              fill="#6f6f6f"
            />
          </div>
          <div className="flex flex-col justify-between items-end relative">
            <span className="font-montserrat text-light-black font-medium text-[11px] max-[900px]:w-min italic">
              {user?.refNo}
            </span>
            {/* Action button section */}
            <div className="flex gap-14">
              <div className="group flex flex-col justify-between items-center cursor-pointer gap-2">
                <div className="border border-primary h-[25px] w-[25px] rounded-full justify-center items-center">
                  <LikeIconLeft className={"w-5 h-5 mt-0.5"} fill={"#2E2E2E"} />
                </div>
                <span className="font-medium uppercase text-[8px] text-light-black">
                  Likes
                </span>
              </div>

              <div className="group flex flex-col justify-between items-center cursor-pointer gap-2">
                <div className="border border-primary h-[25px] w-[25px] rounded-full justify-center items-center">
                  <ReferIcon className="w-6 h-6" />
                </div>
                <span className="font-medium uppercase text-[8px] text-light-black">
                  Refer
                </span>
              </div>

              <div className="group flex flex-col justify-between items-center cursor-pointer gap-2">
                <div className="border border-primary h-[25px] w-[25px] rounded-full justify-center items-center">
                  <TrackingIcon className={"w-6 h-6"} fill={"#2E2E2E"} />
                </div>
                <span className="font-medium uppercase text-[8px] text-light-black">
                  Track
                </span>
              </div>

              <div className="group flex flex-col justify-between items-center cursor-pointer gap-2">
                <div className="border border-primary h-[25px] w-[25px] rounded-full justify-center items-center">
                  <ShareIconOutline className={"w-6 h-6"} />
                </div>
                <span className="font-medium uppercase text-[8px] text-light-black">
                  Share
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Body Wrapper */}
      <div className="pl-48 flex flex-col flex-1 overflow-hidden relative">
        <div className="h-px w-[91%] bg-[#dddddd] absolute right-0 top-[2.7rem]"></div>
        {/* Tab section */}
        <div className="flex items-center justify-between pr-20 mt-[0.8rem]">
          {tabs?.map((item, index) => {
            return (
              <button
                key={index}
                onClick={() => handleTabClick(index)}
                style={{
                  borderBottom:
                    activeTab === index
                      ? "2px solid #EFBF04"
                      : "2px solid transparent",
                }}
                className="pb-1 px-2.5"
              >
                <span className="font-montserrat text-primary font-medium text-xs">
                  {item}
                </span>
              </button>
            );
          })}
        </div>
        {activeTab !== 2 && (
          <div
            className="flex items-center justify-center rounded border self-end gap-[10px] py-[6px] px-2 mt-5 mr-3 cursor-pointer"
            onClick={() => {
              handleNext();
              setIsEditing(!isEditing);
              setEditingTab(activeTab);
            }}
          >
            {isLoading && isEditing && (
              <LoadingAnimation color={colors.PrimaryBlack} />
            )}
            {isEditing ? (
              <CheckmarkIcon className={"w-6 h-6"} />
            ) : (
              <EditIcon className={"w-6 h-6"} fill="#4d4d4d" />
            )}
            <span className="font-montserrat text-light-black font-semibold text-[14px]">
              Edit
            </span>
          </div>
        )}

        <div className="flex-1 overflow-y-auto no-scrollbar">
          <RenderBody
            ref={formRef}
            activeTab={activeTab}
            isEditing={isEditing}
            editingTab={editingTab}
            handleSave={handleSave}
            handleEditClose={() => setIsEditing(false)}
          />
        </div>
      </div>

      {fields?.openProfileModel && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"
          onClick={closeModal} // Close modal when clicking outside the image
        >
          <div
            className="flex overflow-hidden flex-col bg-white py-6 px-6 rounded-lg relative w-11/12 max-w-[50%]"
            onClick={(e) => e.stopPropagation()} // Prevent modal from closing when clicking inside
          >
            <div className="flex flex-1 items-center justify-between pb-6">
              <span className="font-montserrat text-primary font-bold text-xl">
                Edit
              </span>
              <button
                onClick={closeModal}
                className="self-end text-black text-2xl font-semibold"
              >
                X
              </button>
            </div>
            {!fields?.image && (
              <div className="flex flex-1 px-8 gap-14">
                <div className="flex flex-col flex-1 max-w-[40%] gap-6">
                  <div className="flex self-center rounded-full h-40 w-40 bg-[#E1E0E0] items-center justify-center">
                    {fields?.profileImage || user?.profilePic ? (
                      <img
                        src={fields?.profileImage || user?.profilePic}
                        className="rounded-full h-40 w-40 object-fill"
                      />
                    ) : (
                      <span className="font-montserrat text-white font-semibold text-6xl">
                        {user?.firstName?.charAt(0)}
                        {user?.lastName?.charAt(0)}
                      </span>
                    )}
                  </div>
                  <button
                    type="button"
                    onClick={() => filePickerRef?.current?.click()}
                    className="flex  items-center border rounded-lg overflow-hidden pr-2"
                  >
                    <span className="text-sm text-[#6f6f6f] font-medium py-2.5 px-3 bg-[#E9EAF0]">
                      PNG/JPG
                    </span>
                    <span className="text-xs flex font-medium px-3 text-ngray flex-1 text-left whitespace-nowrap">
                      {fields?.file
                        ? fields?.file?.name
                        : user?.profilePic
                        ? user?.profilePic
                        : "Upload your profile picture"}
                    </span>
                  </button>
                  <input
                    ref={filePickerRef}
                    type="file"
                    className="hidden"
                    onChange={handleFileChange}
                  />
                </div>
                <div className="flex flex-1 flex-col gap-5">
                  <CustomInput
                    label={"Job Title"}
                    name="jobTitle"
                    placeholder={"Ex: Project Manager"}
                    control={control}
                    errors={errors}
                  />
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => {
                      return (
                        <div className={`flex flex-col w-full`}>
                          <span className="font-montserrat text-primary font-medium text-xs mb-2">
                            Work Status
                          </span>
                          <div
                            className={`rounded-lg py-2.5 px-3 font-montserrat font-medium text-xs flex items-center border`}
                          >
                            <select
                              {...field}
                              className="w-full outline-none bg-transparent focus:outline-none focus:border-blue-500 text-primary"
                              name={"status"}
                              id={"status"}
                            >
                              <option value="" disabled hidden className={""}>
                                Are you available for job?
                              </option>
                              {availabilityStatus?.map((item) => {
                                return (
                                  <option
                                    className="text-black"
                                    key={item?.id}
                                    value={item?.id}
                                  >
                                    {item?.title}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          {errors["status"]?.message && (
                            <span className="whitespace-pre text-xs mt-2 text-orange-700">
                              {errors["status"]?.message}
                            </span>
                          )}
                        </div>
                      );
                    }}
                  />
                  <CustomInput
                    label={"Location"}
                    name="location"
                    placeholder={"Ex: United Kingdom"}
                    control={control}
                    errors={errors}
                  />
                </div>
              </div>
            )}

            <div className="flex flex-col mt-6">
              {fields?.image && (
                <Cropper
                  src={fields?.image}
                  ref={cropperRef}
                  style={{ height: 400, width: "100%" }}
                  aspectRatio={1}
                  viewMode={2}
                  guides={false}
                />
              )}
              <style>
                {`
                    /* Ensure the cropper's crop box is circular */
                    .cropper-face,
                    .cropper-view-box,
                    .cropper-crop-box {
                      border-radius: 50% !important; /* Make crop area circular */
                      border: 2px solid white !important; /* Optional: Set the color of the circular crop lines */
                    }

                    .cropper-face {
                      background-color: rgba(0, 0, 0, 0.3) !important; /* Optional: Set the background color of the circular crop area */
                    }

                    .cropper-view-box {
                      border-radius: 50% !important; /* Make sure the selection area is circular */
                    }

                    .cropper-crop-box {
                      border-radius: 50% !important; /* Ensure the cropping boundary is circular */
                    }
                  `}
              </style>
              <div className="mt-10 flex justify-end">
                <button
                  disabled={isLoading}
                  onClick={(e) => {
                    if (fields?.image) {
                      getCroppedImage(e);
                    } else {
                      updateProfile(fields);
                    }
                  }}
                  className="bg-primary items-center flex text-white px-7 py-2.5 text-xl rounded-md"
                >
                  {isLoading && <LoadingAnimation color={colors.White} />}
                  {fields?.image ? "Crop Image" : "Save"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
