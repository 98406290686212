import { useEffect, useState } from "react";

const TickThinOutline = ({ className, fill }) => {
  const [_class, setClass] = useState(className);

  useEffect(() => {
    let classStr = className || "";
    if (!classStr.includes("w-")) {
      classStr += " w-5";
    }
    if (!classStr.includes("h-")) {
      classStr += " h-5";
    }

    setClass(classStr);
  }, [className]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
      class={_class}
    >
      <path
        d="M8.16667 15C7.91667 15 7.75 14.9167 7.58333 14.75L3.5 10.4167C3.16667 10.0833 3.16667 9.58333 3.5 9.25C3.83333 8.91667 4.33333 8.91667 4.66667 9.25L8.08333 12.9167L15.0833 5.25C15.3333 4.91667 15.9167 4.83333 16.25 5.08333C16.5833 5.33333 16.6667 5.91667 16.4167 6.25L16.3333 6.33333L8.75 14.6667C8.66667 14.9167 8.41667 15 8.16667 15Z"
        fill="#02BC7D"
      />
    </svg>
  );
};

export default TickThinOutline;
