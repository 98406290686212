import * as React from "react";
const CheckmarkIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <path
      stroke="#4D4D4D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.25}
      d="m4.125 13.125 5.25 5.25 10.5-11.25"
    />
  </svg>
);
export default CheckmarkIcon;
