import PlusIcon from "../../assets/svg/PlusIcon";
import ChevronDown from "../../assets/svg/ChevronDown";
import SearchIcon from "../../assets/svg/SearchIcon";
import GroupIcon from "../../assets/svg/GroupIcon";
//@ts-ignore
import ProfileImg from "../../assets/images/profilepic.png";
import { useNavigate } from "react-router-dom";
import Phone from "../../assets/svg/Phone";
import Email from "../../assets/svg/Email";
import { useEffect, useState } from "react";
import { accountMembers } from "../../endpoints/recruiter.service";
import { recruiterApprovalStatus } from "../../utils/constants";
import Spinner from "../../components/Spinner/Spinner";

const AccountMembers = () => {
  const navigate = useNavigate();
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    accountMembers({
      page: 1,
      limit: 10,
    })
      .then((response) => {
        const { data, success } = response;
        if (success) {
          setMembers(data);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  const handleItemClick = (item) => {
    navigate(`/account/account-members/${item?._id}`, { state: { item } });
  };

  return (
    <div className="flex flex-col overflow-y-hidden h-full rounded-lg bg-white w-full flex-grow p-4">
      {/* Search and filter section */}
      <p className="text-center text-sm font-bold underline">
        Barclay Simpson Staff Members
      </p>
      <div className="flex items-center justify-between w-full my-8">
        <div className="flex items-center gap-8">
          {/* Input container */}
          <div className="flex rounded-lg gap-3 items-center justify-between bg-light-white-500 px-3 py-1.5">
            <button className="flex items-center justify-center border bg-white rounded-md px-4 py-1 gap-2">
              <label className="text-primary text-sm font-medium">
                Search by
              </label>
              <ChevronDown className={"w-4 h-4"} fill={"#2e2e2e"} />
            </button>
            <input
              className="bg-white text-xs font-medium text-light-black outline-none px-3 py-2 rounded-lg w-80"
              placeholder="Search by Name, Title or Ref number..."
            />
            <SearchIcon className={"w-6 h-6 cursor-pointer"} fill={"#2e2e2e"} />
          </div>
        </div>

        <button
          onClick={() => navigate("/contacts/add-candidate")}
          className="flex items-center justify-center border bg-green-600 rounded-md px-2 py-1 gap-1 text-white font-semibold text-sm"
        >
          <PlusIcon className={"w-4 h-4"} fill={"white"} />
          Invite Members
        </button>
      </div>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <Spinner />
        </div>
      ) : members?.length === 0 ? (
        <div className="flex flex-1 h-screen flex-col items-center justify-center">
          <p className="text-primary font-semibold">No Account Member</p>
          <p className="mt-2 mb-5">Invite Members now!</p>
        </div>
      ) : (
        <div className="flex flex-1 h-full flex-col overflow-y-scroll no-scrollbar">
          {members.map((data) => (
            <div
              className="flex cursor-pointer flex-col"
              onClick={() => handleItemClick(data)}
            >
              <div className="bg-[#FAFAFA] flex justify-between text-xs px-5 py-2 cursor-pointer text-[#2E2E2E]">
                <div className="flex items-center w-full justify-between">
                  <div className="w-1/5">
                    <img
                      src={ProfileImg}
                      alt="profile img"
                      className="h-10 w-10 bg-gray-500 rounded-full"
                    />
                  </div>
                  <div className="flex flex-col gap-[6px] w-1/4 text-[#6F6F6F]">
                    <p className="text-[#2E2E2E]">
                      {data.firstName} {data.lastName}
                    </p>
                    <p>{data.agentPosition || "Agent"}</p>
                  </div>
                  <div className="flex flex-col gap-[6px] w-1/4">
                    <p className="flex gap-[5px]">
                      <span>
                        <Phone />
                      </span>
                      {data.phone || "   ----"}
                    </p>
                    <p className="flex gap-[5px]">
                      <span>
                        <Email />
                      </span>
                      {data.email}
                    </p>
                  </div>
                  <p className="w-1/5 text-center">{data.refNo}</p>
                </div>
              </div>
              <div className="flex items-end gap-7 space-y-3 ml-8">
                <div>
                  <GroupIcon className={"w-8 h-8"} />
                </div>
                <div className="flex flex-col flex-1">
                  <div className="flex font-semibold text-xs italic text-[#BABABA] gap-4 mb-[6px]">
                    <p>Received 1:00pm</p>
                    <span>16th November 2024</span>
                  </div>
                  {data.recruiterApprovalStatus ===
                    recruiterApprovalStatus.ALL ||
                  data.recruiterApprovalStatus ===
                    recruiterApprovalStatus.PENDING ? (
                    <div className="bg-[#FAFAFA] text-[#6F6F6F] flex justify-between text-xs font-medium px-5 py-2 cursor-pointer">
                      <p className="text-[#A43370] text-xs px-[10px] py-1 bg-[#F7DEEC] rounded">
                        Pending ABC Recruitment Admin Approval
                      </p>
                      <p className="w-1/5 text-center">{data.id}</p>
                    </div>
                  ) : data.recruiterApprovalStatus ===
                    recruiterApprovalStatus.APPROVED ? (
                    <div className="bg-[#FAFAFA] text-[#6F6F6F] flex justify-between text-xs font-medium px-5 py-2 cursor-pointer">
                      <p className="text-[#17D72A] text-xs    ">
                        ABC Recruitment Admin Approved{" "}
                        <span className="italic text-[#BABABA] text-xs ml-4">
                          by John clark
                        </span>
                      </p>
                      <p className="w-1/5 text-center">{data.id}</p>
                    </div>
                  ) : (
                    <div className="bg-[#FAFAFA] text-[#6F6F6F] flex justify-between text-xs font-medium px-5 py-2">
                      <p className="w-1/4">{data.title}</p>
                      <div className="flex w-[40%] gap-2">
                        {data.key === "applied" ? (
                          <span>{data.mode}</span>
                        ) : (
                          <>
                            <span>{data.time}</span>
                            <span>{data.date}</span>
                          </>
                        )}
                      </div>
                      <p className="w-1/4">
                        {data.key === "call" ? data.number : data.position}
                      </p>
                      <p className="w-1/4 text-center">{data.id}</p>
                    </div>
                  )}
                </div>
              </div>
              <hr className="border-t-1 border-gray-300 my-4" />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AccountMembers;
