import api from "../api";
import axios from "../utils/axios.instance";
import handleError from "./handle-error";

export const addSkill = async (payload) => {
  try {
    const { data } = await axios.post(api.addSkill, payload);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const updateSkill = async (payload) => {
  try {
    const { data } = await axios.patch(api.updateSkill, payload);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const deleteSkill = async (SkillId) => {
  try {
    const { data } = await axios.delete(api.deleteSkill + SkillId);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const getSkills = async (payload) => {
  try {
    const slug =
      payload?.candidateId && payload?.jobId
        ? `candidateId=${payload?.candidateId}&jobId=${payload?.jobId}`
        : payload?.candidateId
        ? `candidateId=${payload?.candidateId}`
        : `jobId=${payload?.jobId}`;
    const { data } = await axios.get(api.listSkill + `?type=1&${slug}`);
    return data;
  } catch (err) {
    return handleError(err);
  }
};
