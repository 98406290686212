import { Controller, useForm } from "react-hook-form";
import CustomInput from "../../../components/CustomInput/CustomInput";
import Switch from "../../../components/Switch/Switch";
import QuillEditor from "../../../components/CreateQuestion/Question/QuillEditor";
import { JobType } from "../../../utils/constants";

const Step2 = ({ errors, control }) => {
  return (
    <div className="w-[80%] self-center">
      <div className="flex flex-col gap-7 w-1/2">
        <CustomInput
          label="Job Title"
          placeholder="Jr. IT Analyst"
          name="jobTitle"
          rules={{
            required: "This field is required.",
          }}
          control={control}
          errors={errors}
        />

        <CustomInput
          label="Job Location"
          placeholder="Enter Job Location"
          name="jobLocation"
          rules={{
            required: "This field is required.",
          }}
          control={control}
          errors={errors}
        />
        <CustomInput
          label="Industry Type"
          placeholder="Enter Industry Type"
          name="industryType"
          rules={{
            required: "This field is required.",
          }}
          control={control}
          errors={errors}
        />
        <CustomInput
          label="Job Type"
          placeholder="Full-time/Contract/Part-time"
          name="jobType"
          rules={{
            required: "This field is required.",
          }}
          control={control}
          errors={errors}
          type="dropdown"
          dropdownList={JobType?.map((x) => x.label)}
        />
        <CustomInput
          label="Salary Range"
          placeholder="ex. 100000 - 200000"
          name="salaryRange"
          rules={{
            required: "This field is required.",
          }}
          control={control}
          errors={errors}
        />
        <div>
          <Controller
            control={control}
            name="isSalaryHidden"
            render={({ field: { onChange, value } }) => (
              <Switch isChecked={value} onToggle={onChange} />
            )}
          />
          <label className="text-xs text-light-grey">
            Hide salary from your ad
          </label>
        </div>
      </div>
      <div style={{ position: "relative" }} className="w-[80%] mt-10">
        <Controller
          label="Job Description"
          name="jobDescription"
          rules={{
            required: "This field is required.",
          }}
          control={control}
          errors={errors}
          render={({ field: { value, onChange } }) => (
            <QuillEditor
              label="Job Description"
              required
              value={value}
              onChange={onChange}
              errors={errors}
            />
          )}
        />
      </div>
    </div>
  );
};

export default Step2;
