import { useEffect, useState } from "react";

const IndustryIcon = ({ className, fill }) => {
  const [_class, setClass] = useState(className);

  useEffect(() => {
    let classStr = className || "";
    if (!classStr.includes("w-")) {
      classStr += " w-5";
    }
    if (!classStr.includes("h-")) {
      classStr += " h-5";
    }

    setClass(classStr);
  }, [className]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      stroke-width="0"
      stroke={fill}
      class={_class}
    >
      <path d="M7.0625 5.7487C7.0625 6.0592 6.8105 6.3112 6.5 6.3112H4.25C3.9395 6.3112 3.6875 6.0592 3.6875 5.7487C3.6875 5.4382 3.9395 5.1862 4.25 5.1862H6.5C6.8105 5.1862 7.0625 5.4382 7.0625 5.7487ZM6.5 7.4362H4.25C3.9395 7.4362 3.6875 7.6882 3.6875 7.9987C3.6875 8.3092 3.9395 8.5612 4.25 8.5612H6.5C6.8105 8.5612 7.0625 8.3092 7.0625 7.9987C7.0625 7.6882 6.8105 7.4362 6.5 7.4362ZM15.3125 8.23795V13.2487C15.3125 14.3865 14.3877 15.3112 13.25 15.3112H2.75C1.61225 15.3112 0.6875 14.3865 0.6875 13.2487V4.6447C0.6875 3.71695 1.31225 2.8987 2.207 2.65495L8.405 0.965202C8.80325 0.856452 9.2195 0.937452 9.54575 1.18795C9.87425 1.43845 10.0617 1.8187 10.0617 2.2312V4.6282L14.0623 6.3427C14.8212 6.66895 15.3118 7.41295 15.3118 8.2387L15.3125 8.23795ZM6.6875 14.1862V11.7487C6.6875 11.6452 6.6035 11.5612 6.5 11.5612H4.25C4.1465 11.5612 4.0625 11.6452 4.0625 11.7487V14.1862H6.6875ZM8.9375 2.23045C8.9375 2.1502 8.891 2.10295 8.86325 2.0812C8.843 2.06545 8.80325 2.04295 8.7515 2.04295C8.73575 2.04295 8.71925 2.04445 8.702 2.0497L2.50325 3.73945C2.09675 3.85045 1.8125 4.22245 1.8125 4.64395V13.248C1.8125 13.7647 2.23325 14.1855 2.75 14.1855H2.9375V11.748C2.9375 11.0242 3.52625 10.4355 4.25 10.4355H6.5C7.22375 10.4355 7.8125 11.0242 7.8125 11.748V14.1855H8.9375V2.23045ZM14.1875 8.23795C14.1875 7.86295 13.964 7.52395 13.619 7.3762L10.0625 5.85145V14.1862H13.25C13.7668 14.1862 14.1875 13.7655 14.1875 13.2487V8.23795ZM12.5 8.9362H11.75C11.4395 8.9362 11.1875 9.1882 11.1875 9.4987C11.1875 9.8092 11.4395 10.0612 11.75 10.0612H12.5C12.8105 10.0612 13.0625 9.8092 13.0625 9.4987C13.0625 9.1882 12.8105 8.9362 12.5 8.9362ZM12.5 11.1862H11.75C11.4395 11.1862 11.1875 11.4382 11.1875 11.7487C11.1875 12.0592 11.4395 12.3112 11.75 12.3112H12.5C12.8105 12.3112 13.0625 12.0592 13.0625 11.7487C13.0625 11.4382 12.8105 11.1862 12.5 11.1862Z" fill={fill} stroke={fill} stroke-width="0.2" />
    </svg>
  );
};

export default IndustryIcon;
