import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import ChevronLeftIcon from "../../../assets/svg/ChevronLeftIcon";
import { getJobs } from "../../../endpoints/job.service";
import Spinner from "../../../components/Spinner/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { setJobDetail } from "../../../store/actions/job.action";

const tabs = [
  { name: "Job Post Details", path: "job-post" },
  { name: "Skills Listing", path: "skills" },
  { name: "Applicants List", path: "applicants" },
];

const JobDetail = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { jobId } = useParams();
  const { jobDetail } = useSelector((state) => state?.job);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    getJobs({ jobId })
      .then((response) => {
        const { data, success } = response;
        if (success) {
          dispatch(setJobDetail(data?.[0]));
        }
      })
      .finally(() => setLoading(false));
  }, [jobId]);

  return (
    <div className="flex flex-col rounded-lg bg-white w-full flex-grow p-4">
      <button
        onClick={() => navigate(-1)}
        className="flex items-center self-start"
      >
        <ChevronLeftIcon className={"w-4 h-4"} fill="#4d4d4d" />
        <label className="text-xs font-medium text-primary cursor-pointer">
          Back to listing
        </label>
      </button>

      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <Spinner />
        </div>
      ) : (
        <>
          {/* Header section */}
          <div className="flex items-center justify-between rounded-lg border bg-[#FAFAFA] px-6 py-4 mt-5">
            <div>
              <p className="text-xs font-medium font-inter text-[#6F6F6F] tracking-[0.2px]">
                Job Title
              </p>
              <p className="text-xs font-semibold font-montserrat text-[#2E2E2E]">
                {jobDetail?.title ?? "-"}
              </p>
            </div>
            <div>
              <p className="text-xs font-medium font-inter text-[#6F6F6F] tracking-[0.2px]">
                Industry Type
              </p>
              <p className="text-xs font-semibold font-montserrat text-[#2E2E2E]">
                {jobDetail?.industryType ?? "-"}
              </p>
            </div>
            <div>
              <p className="text-xs font-medium font-inter text-[#6F6F6F] tracking-[0.2px]">
                Location
              </p>
              <p className="text-xs font-semibold font-montserrat text-[#2E2E2E]">
                {jobDetail?.location ?? "-"}
              </p>
            </div>
          </div>

          {/* Tabs Section */}
          <div className="flex items-center gap-24 border-b px-3 mt-4">
            {tabs?.map((item, index) => {
              return (
                <button
                  onClick={() => navigate(item?.path)}
                  key={index}
                  className={`${
                    pathname.includes(item?.path) ||
                    (pathname === "/job/jobdetail" && index === 0)
                      ? "border-secondary"
                      : "border-transparent text-[#BABABA]"
                  } text-primary text-sm font-medium border-b-2 py-1 px-3`}
                >
                  {item?.name}
                </button>
              );
            })}
          </div>
          <div className="flex flex-1 flex-col overflow-y-hidden no-scrollbar">
            <Outlet />
          </div>
        </>
      )}
    </div>
  );
};

export default JobDetail;
