import { Controller, useForm } from "react-hook-form";
import PlusIcon from "../../../assets/svg/PlusIcon";
import SaveIcon from "../../../assets/svg/SaveIcon";
import TrashIcon from "../../../assets/svg/TrashIcon";
import CustomSlider from "../../../components/CustomSlider/CustomSlider";
import CustomInput from "../../../components/CustomInput/CustomInput";
import { useCallback } from "react";
import { JobSkills } from "../../../utils/constants";
import {
  addSkill,
  updateSkill,
  deleteSkill,
} from "../../../endpoints/skill.service";
import { useSnackbar } from "notistack";
import ThreeDotsVertical from "../../../assets/svg/ThreeDotsVertical";
import { useLocation } from "react-router-dom";

const ProgressBar = ({ value }) => {
  return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]?.map((item) => {
    return (
      <div
        key={item}
        className={`w-4 h-3 ${
          value >= item + 1 ? "bg-[#EFBF04]" : "bg-[#ececec]"
        }`}
      />
    );
  });
};

export const SkillInput = ({
  data,
  addUpdateSkill,
  index,
  deleteSkill,
  editSkill,
}) => {
  const {
    watch,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      ...data,
      lastUsed: "current",
    },
  });
  const field = watch();

  const onSave = () => {
    addUpdateSkill(field, index);
  };

  if (data?.isSaved) {
    return (
      <div
        key={index}
        className={`flex items-center py-4 ${index !== 0 ? "border-t" : ""}`}
      >
        <label
          style={{ width: "25%" }}
          className="flex text-xs font-medium text-primary"
        >
          {data?.title}
        </label>
        <div
          style={{ width: "25%" }}
          className="flex text-xs font-medium text-primary gap-1.5"
        >
          <ProgressBar value={data?.level} />
        </div>
        <label
          style={{ width: "25%" }}
          className="flex text-xs font-medium text-primary justify-center"
        >
          {data?.experience}
        </label>
        <label
          style={{ width: "20%" }}
          className="flex text-xs font-medium text-primary"
        >
          {data?.lastUsed}
        </label>
        <div
          style={{ width: "5%" }}
          onClick={() => editSkill(index)}
          className="flex text-xs font-medium text-primary cursor-pointer justify-center"
        >
          <ThreeDotsVertical className={"w-4 h-4"} fill={"#6f6f6f"} />
        </div>
      </div>
    );
  }
  return (
    <div className="flex items-center px-4 py-3 bg-light-white-700 rounded-lg mb-5">
      <div
        style={{ width: "25%" }}
        className="flex flex-col  text-sm text-primary"
      >
        <Controller
          control={control}
          name="skill"
          rules={{
            required: "This field is required.",
          }}
          render={({ field: { value, onChange } }) => (
            <input
              className="font-normal rounded-md bg-white px-3 py-2 text-xs w-[85%]"
              placeholder="Javascript"
              value={value}
              onChange={(c) => onChange(c.target.value)}
            />
          )}
        />
        {errors?.skill?.message && (
          <span className="whitespace-pre text-xs mt-2 text-orange-700">
            {errors?.skill?.message}
          </span>
        )}
      </div>
      <div
        style={{ width: "25%" }}
        className="flex flex-col  text-sm text-primary"
      >
        <Controller
          control={control}
          name="level"
          rules={{
            required: "This field is required.",
          }}
          render={({ field: { value, onChange } }) => (
            <CustomSlider
              step={1}
              min={0}
              max={10}
              fillerClass={`h-[5px]`}
              defaultValue={5}
              value={value}
              labelStyle={`text-xs`}
              onChange={onChange}
            />
          )}
        />
        {errors?.level?.message && (
          <span className="whitespace-pre text-xs mt-2 text-orange-700">
            {errors?.level?.message}
          </span>
        )}
      </div>
      <label
        style={{ width: "25%" }}
        className="flex flex-col font-semibold text-sm text-primary items-center justify-center"
      >
        <Controller
          control={control}
          name="experience"
          rules={{
            required: "This field is required.",
          }}
          render={({ field: { value, onChange } }) => (
            <input
              className="font-normal rounded-md bg-white px-3 py-2 text-xs w-1/2"
              placeholder="9"
              value={value}
              onChange={(c) => onChange(c.target.value)}
            />
          )}
        />
        {errors?.experience?.message && (
          <span className="whitespace-pre text-xs mt-2 text-orange-700">
            {errors?.experience?.message}
          </span>
        )}
      </label>
      <div style={{ width: "20%" }} className="flex text-sm text-primary">
        <div className="w-[60%]">
          <CustomInput
            name="lastUsed"
            control={control}
            errors={errors}
            type="dropdown"
            dropdownList={["current", "this year", "last year", "2+ years"]}
            rules={{
              required: "This field is required.",
            }}
          />
        </div>
      </div>
      <div className="flex flex-col w-[5%] items-end gap-3">
        <div className="cursor-pointer" onClick={handleSubmit(onSave)}>
          <SaveIcon className={"w-4 h-4"} />
        </div>
        <div className="cursor-pointer" onClick={() => deleteSkill(index)}>
          <TrashIcon className={"w-4 h-4"} />
        </div>
      </div>
    </div>
  );
};

const Step3 = ({ createdDraftJob }) => {
  const { state } = useLocation();
  const { setValue, watch } = useForm({
    defaultValues: {
      skills: state
        ? state?.skills?.map((x) => ({
            ...x,
            skill: x?.title,
            level: x?.rating,
            experience: x?.years,
            lastUsed: JobSkills.find((j) => j.value === x?.lastUsed)?.label,
            isSaved: true,
            isEdit: false,
          }))
        : [],
    },
  });
  const { enqueueSnackbar } = useSnackbar();
  const field = watch();

  const addNewskill = () => {
    setValue("skills", [{ isSaved: false, isEdit: true }, ...field?.skills]);
  };

  const addUpdateSkill = useCallback(
    async (data, index) => {
      try {
        let response;
        const lastUsed = JobSkills.find((x) => x.label === data?.lastUsed);
        if (data?._id) {
          response = await updateSkill({
            id: data?._id,
            title: data?.skill,
            rating: data?.level,
            years: data?.experience,
            lastUsed: lastUsed?.value,
          });
        } else {
          response = await addSkill({
            title: data?.skill,
            rating: data?.level,
            years: data?.experience,
            lastUsed: lastUsed?.value,
            jobId: createdDraftJob?._id,
            type: 2,
            userType: 2,
          });
        }

        if (response?.success) {
          let newSkills = field.skills;
          newSkills[index] = {
            ...data,
            ...response?.data,
            isEdit: false,
            isSaved: true,
          };
          setValue("skills", [...newSkills]);
        }
      } catch (error) {
        enqueueSnackbar(error, { variant: "error" });
      }
    },
    [field]
  );

  const deleteSkillPress = useCallback(
    async (index) => {
      try {
        const skill = field.skills[index];
        if (skill?.isSaved === false) {
          let n = field?.skills;
          n.splice(index, 1);
          setValue("skills", [...n]);
          return;
        }
        const data = await deleteSkill(skill?._id);

        if (data?.success) {
          let n = field?.skills;
          n.splice(index, 1);
          setValue("skills", [...n]);
          return;
        }
      } catch (error) {
        enqueueSnackbar(error, { variant: "error" });
      }
    },
    [field]
  );

  const editSkill = useCallback(
    async (index) => {
      let newSkills = field.skills;
      newSkills[index] = {
        ...newSkills[index],
        isEdit: true,
        isSaved: false,
      };
      setValue("skills", [...newSkills]);
    },
    [field]
  );

  return (
    <div className="flex flex-col items-center">
      <label className="font-semibold">Skills Required</label>
      <div className="flex flex-col w-full px-4 py-8">
        <div className="flex items-center gap-4">
          <label className="font-medium text-sm text-primary">
            Click here to add a skill
          </label>
          <button
            type="button"
            onClick={addNewskill}
            className="flex items-center text-xs text-primary font-medium bg-[#ececec] px-1.5 py-1 rounded-md gap-1"
          >
            <PlusIcon className={"w-3.5 h-3.5"} fill={"black"} />
            Add Skill
          </button>
        </div>
        {/* List Container */}
        {field?.skills?.length === 0 ? (
          <div className="flex justify-center mt-44">No skill added</div>
        ) : (
          <div className="flex flex-col mt-8">
            <div className="flex items-center pb-3 px-4">
              <label
                style={{ width: "25%" }}
                className="flex font-semibold text-sm text-primary"
              >
                Skill
              </label>
              <label
                style={{ width: "25%" }}
                className="flex font-semibold text-sm text-primary"
              >
                Level out of 10
              </label>
              <label
                style={{ width: "25%" }}
                className="flex font-semibold text-sm text-primary justify-center"
              >
                Years of experience
              </label>
              <label
                style={{ width: "20%" }}
                className="flex font-semibold text-sm text-primary"
              >
                Last used
              </label>
              <div className="w-[5%]" />
            </div>

            {field?.skills?.map((x, index) => {
              return (
                <SkillInput
                  data={x}
                  key={index + new Date()}
                  addUpdateSkill={addUpdateSkill}
                  index={index}
                  deleteSkill={deleteSkillPress}
                  editSkill={editSkill}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Step3;
