import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../components/CustomInput/CustomInput";
import { useRef, useState } from "react";
import { Cropper } from "react-cropper";
import LoadingAnimation from "../../assets/svg/LoadingAnimation";
import { colors } from "../../utils/theme";

const MyCompanyDetailsPage = () => {
  const navigate = useNavigate();
  const cropperRef = useRef(null);
  const filePickerRef = useRef();
  const { user } = useSelector((state) => state.auth);
  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      jobTitle: user?.jobTitle,
      location: user?.location,
      image: undefined,
      profileImage: undefined,
    },
  });
  const [isLoading, setIsLoading] = useState(false);

  const fields = watch();

  const handleFileChange = async (e) => {
    try {
      const file = e.target.files[0];
      setValue("file", file, { shouldDirty: true });
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setValue("image", reader.result, { shouldDirty: true });
        };
        reader.readAsDataURL(file);
      }
    } catch (error) {
      console.error("Error during file upload or API calls:", error);
    }
  };

  const handleItemClick = (item) => {
    navigate("/account-members-detail", { state: { item } });
  };

  const getCroppedImage = (e) => {
    e.preventDefault();

    if (cropperRef.current) {
      const cropperInstance = cropperRef.current.cropper;
      if (cropperInstance) {
        const croppedDataUrl = cropperInstance.getCroppedCanvas().toDataURL();
        setValue("profileImage", croppedDataUrl);
        setValue("image", undefined);
      } else {
        console.error("Cropper instance is not found");
      }
    } else {
      console.error("cropperRef.current is not initialized");
    }
  };

  const updateProfile = async (payload) => {
    return;
    try {
      setIsLoading(true);
      if (payload?.profileImage) {
        const { data } = await profileImage();
        if (!data?.data || !data?.data.uploadURL) {
          throw new Error("Failed to get upload URL");
        }
        const formData = new FormData();
        const base64Data = payload?.profileImage?.split(";base64,").pop();
        const byteArray = Uint8Array.from(atob(base64Data), (c) =>
          c.charCodeAt(0)
        );
        const blob = new Blob([byteArray], { type: payload?.file?.type });
        formData.append("file", blob, payload?.file?.name);
        const fileResponse = await uploadUrl(data?.data.uploadURL, formData);
        delete payload["file"];
        delete payload["profileImage"];
        delete payload["image"];
        const picURl = fileResponse?.variants?.find(
          (x) => x.split("/").slice(-1)[0] === "public"
        );
        payload["profilePic"] = picURl;
      }
      const { success, data: userApiData } = await updateCandidate(payload);
      if (success) {
        dispatch(setUserData(userApiData));
        setValue("profileImage", undefined);
        setValue("image", undefined);
        setValue("openProfileModel", false);
      }
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
      });
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col overflow-y-hidden h-full rounded-lg bg-white w-full flex-grow p-10">
      <div className="flex flex-col gap-5 rounded-md bg-gray-50 px-8 py-6">
        <div className="flex flex-col gap-1.5">
          <label className="font-bold text-lg text-primary">
            Update Your Company Details
          </label>
          <span className="font-medium text-xs text-gray-500">
            Keep Your Business Information Up to Date.
          </span>
        </div>
        <div className="grid grid-cols-2 gap-5">
          <CustomInput
            label="Company Name"
            name="companyName"
            placeholder={"ABC Company"}
            control={control}
            errors={errors}
          />
          <CustomInput
            label="Registered Office Address"
            name="registeredOfficeAddress"
            placeholder={"Ex: doe"}
            control={control}
            errors={errors}
          />
          <CustomInput
            label="City"
            name="city"
            placeholder={"Ex: Project Manager"}
            control={control}
            errors={errors}
          />
          <CustomInput
            label={"State/Province"}
            name="state"
            placeholder={"Ex: United Kingdom"}
            control={control}
            errors={errors}
          />
          <CustomInput
            label={"Postal/ZIP Code"}
            name="postalCode"
            placeholder={"Ex: United Kingdom"}
            control={control}
            errors={errors}
          />
          <CustomInput
            label={"Country"}
            name="country"
            placeholder={"Ex: United Kingdom"}
            control={control}
            errors={errors}
          />
        </div>
        <button
          disabled={isLoading}
          onClick={(e) => {
            // if (fields?.image) {
            //   getCroppedImage(e);
            // } else {
            //   updateProfile(fields);
            // }
          }}
          className="bg-primary items-center flex text-white px-8 py-2 text-sm font-semibold rounded-md w-max ml-auto mt-3 hover:bg-black"
        >
          {isLoading && <LoadingAnimation color={colors.White} />}
          Update
        </button>
      </div>
    </div>
  );
};

export default MyCompanyDetailsPage;
