import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import QuillEditor from "../../../components/CreateQuestion/Question/QuillEditor";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { getValueOrPlaceholder } from "../../../utils/helperFunction";

const About = forwardRef(({ isEditing, setEditingTab, handleSave }, ref) => {
  const { user } = useSelector((state) => state.auth);
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      about: user?.about,
    },
  });

  useImperativeHandle(ref, () => handleSubmit(handleSave));

  useEffect(() => {
    if (isEditing) {
      setValue("about", user?.about ?? "");
    }
  }, [user?.about, isEditing]);

  return (
    <div>
      {!isEditing ? (
        <div className="h-full flex flex-1 flex-col overflow-y-scroll no-scrollbar w-4/5">
          <div className="flex flex-col">
            <span className="font-montserrat text-[#383838] font-bold text-sm mb-8">
              About
            </span>
            <div
              className="font-montserrat text-primary font-medium text-xs leading-5 mb-3"
              dangerouslySetInnerHTML={{
                __html: user?.about,
              }}
            />
          </div>
        </div>
      ) : (
        <div className="flex flex-col">
          <span className="font-montserrat text-[#383838] font-bold text-sm mb-8">
            About
          </span>
          <div className="mr-4">
            <Controller
              name={"about"}
              control={control}
              rules={{
                required: "This field is required.",
              }}
              render={({ field }) => {
                return (
                  <div className="flex flex-col gap-1">
                    <QuillEditor {...field} />
                    {errors["about"]?.message && (
                      <span className="whitespace-pre text-xs mt-2 text-orange-700">
                        {errors["about"]?.message}
                      </span>
                    )}
                  </div>
                );
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
});

export default About;
