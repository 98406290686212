import { useNavigate } from "react-router-dom";
import ChevronDown from "../../assets/svg/ChevronDown";
import PlusIcon from "../../assets/svg/PlusIcon";
import SearchIcon from "../../assets/svg/SearchIcon";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setJobs } from "../../store/actions/job.action";
import { getJobs } from "../../endpoints/job.service";
import dayjs from "dayjs";
import { datePostedType, JobStatus, JobType } from "../../utils/constants";
import CustomInput from "../../components/CustomInput/CustomInput";
import { useForm } from "react-hook-form";
import { isNullOrEmpty } from "../../utils/helperFunction";

const JobPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { jobs } = useSelector((state) => state?.job);
  const [loading, setLoading] = useState(jobs?.length === 0);
  const {
    control,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      search: undefined,
      searchBy: "Search by",
    },
  });
  const fields = watch();

  useEffect(() => {
    fetchJobs();
  }, [fields?.search]);

  const fetchJobs = () => {
    getJobs(
      !isNullOrEmpty(fields?.search) && {
        filter: {
          ...(fields?.searchBy === "Search by" && {
            searchText: fields.search,
          }),
          ...(fields?.searchBy === "Industry Type" && {
            industryType: fields.search,
          }),
          ...(fields?.searchBy === "Job Type" && {
            jobType: JobType.find((x) => x.label === fields.search)?.value,
          }),
          ...(fields?.searchBy === "Date Posted" && {
            datePosted: datePostedType.find((x) => x.label === fields.search)
              ?.value,
          }),
        },
      }
    )
      .then((response) => {
        const { data, success } = response;
        if (success) {
          dispatch(setJobs(data));
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="flex flex-col rounded-lg bg-white w-full flex-grow p-4">
      {/* Search and filter section */}
      <div className="flex  items-center justify-between w-full gap-8">
        <div className="flex flex-1 w-full items-center gap-8">
          {/* Input container */}
          <div className="flex w-2/3 rounded-lg gap-3 items-center justify-between bg-light-white-500 px-3 py-1.5">
            <div className="min-w-[28%]">
              <CustomInput
                name="searchBy"
                control={control}
                errors={errors}
                type="dropdown"
                dropdownList={[
                  "Search by",
                  "Industry Type",
                  "Job Type",
                  "Date Posted",
                ]}
              />
            </div>
            <CustomInput
              key={fields.searchBy}
              name="search"
              control={control}
              className="border-none"
              errors={errors}
              placeholder={
                (fields.searchBy === "Search by" &&
                  "Enter value for Search by") ||
                (fields.searchBy === "Industry Type" &&
                  "Enter value for Industry Type")
              }
              type={
                fields.searchBy === "Search by" ||
                fields.searchBy === "Industry Type"
                  ? "text"
                  : "dropdown"
              }
              dropdownList={
                fields.searchBy === "Job Type"
                  ? JobType.map((x) => x.label)
                  : datePostedType?.map((x) => x.label)
              }
            />
            <SearchIcon className={"w-6 h-6"} fill={"#2e2e2e"} />
          </div>
          <button className="flex items-center justify-center border bg-white rounded-md px-4 py-1 gap-4">
            <label className="text-primary text-sm font-medium">All Jobs</label>
            <ChevronDown className={"w-4 h-4"} fill={"#2e2e2e"} />
          </button>
        </div>

        <button
          onClick={() => navigate("create")}
          className="flex items-center justify-center border bg-green-600 rounded-md px-2 py-1 gap-1 text-white font-medium text-sm"
        >
          <PlusIcon className={"w-4 h-4"} fill={"white"} />
          Create Job Post
        </button>
      </div>

      {/* List container */}
      {loading ? (
        <div className="gap-2.5 flex mt-16 flex-col">
          {Array.from({ length: 10 }, (x, i) => i).map((key) => {
            return (
              <div className="flex justify-between">
                <div
                  style={{
                    borderWidth: 1,
                  }}
                  className="flex rounded-lg w-full pl-3 pr-8 py-4 gap-1.5"
                >
                  <div className="h-3 rounded bg-gray-200 w-1/4"></div>
                  <div className="h-3 rounded bg-gray-200 w-1/4"></div>
                  <div className="h-3 rounded bg-gray-200 w-1/4"></div>
                </div>
              </div>
            );
          })}
        </div>
      ) : jobs?.length === 0 ? (
        <div className="flex flex-1 h-screen flex-col items-center justify-center">
          <p className="text-primary font-semibold">No Job created</p>
          <p className="mt-2 mb-5">Create a Job now!</p>
          <button
            onClick={() => navigate("create")}
            className="flex items-center justify-center border bg-green-600 rounded-md px-2 py-1 gap-1 text-white font-medium text-sm"
          >
            <PlusIcon className={"w-4 h-4"} fill={"white"} />
            Create Job Post
          </button>
        </div>
      ) : (
        <div className="flex flex-col mt-4 max-h-fit overflow-y-scroll no-scrollbar gap-2">
          <table class="table-auto mt-3">
            <thead className="border-b">
              <tr>
                <th className="text-left font-semibold py-3 pl-6 text-sm text-primary">
                  Job Reference
                </th>
                <th className="text-left font-semibold py-3 text-sm text-primary">
                  Job Title
                </th>
                <th className="text-left font-semibold py-3 text-sm text-primary">
                  Company Name
                </th>
                <th className="text-left font-semibold py-3 text-sm text-primary">
                  Publish date
                </th>
                <th className="text-left font-semibold py-3 pr-6 text-sm text-primary">
                  Live
                </th>
              </tr>
            </thead>
            <tbody>
              {jobs?.map((job) => {
                return (
                  <tr
                    key={job?._id}
                    className="hover:bg-light-white-700 cursor-pointer"
                    onClick={() => navigate(`/job/${job._id}/job-post`)}
                  >
                    <td className="font-medium py-3 pl-6 border-b text-xs text-light-black">
                      {job?.companyDetails?.refNo ?? "-"}
                    </td>
                    <td className="font-medium py-3 border-b text-xs text-light-black">
                      {job?.title ?? "-"}
                    </td>
                    <td className="font-medium py-3 border-b text-xs text-light-black">
                      {job?.companyDetails?.name ?? "-"}
                    </td>
                    <td className="font-medium py-3 border-b text-xs text-light-black">
                      {dayjs(job?.createdAt).format("DD MMM YYYY") ?? "-"}
                    </td>
                    <td className="font-medium py-3 pr-6 border-b text-xs text-light-black">
                      {job?.status === JobStatus.LIVE
                        ? "Live"
                        : job?.status === JobStatus.CLOSED
                        ? "Closed"
                        : "Draft"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default JobPage;
