import { useSelector } from "react-redux";
import SearchIcon from "../../../assets/svg/SearchIcon";
import AboutSection from "../../ContactsPage/CompaniesSection/AddCompanyPage/components/AboutSection";
import AddContactSection from "../../ContactsPage/CompaniesSection/AddCompanyPage/components/AddContactSection";
import Spinner from "../../../components/Spinner/Spinner";
import { useCallback, useMemo, useState } from "react";
import { isNullOrEmpty } from "../../../utils/helperFunction";

const Step1 = ({
  setCreateNewCompany,
  createNewCompany,
  loading,
  setValue,
  fields,
}) => {
  const { companies } = useSelector((state) => state?.company);
  const [search, setSearch] = useState();
  const onCreateNewCompany = () => {
    setCreateNewCompany(true);
  };

  const filterdCompanies = useMemo(() => {
    if (isNullOrEmpty(search)) {
      return companies;
    }

    return companies.filter(
      (x) =>
        String(x?.name)
          ?.toLowerCase()
          .includes(search?.toLowerCase()?.trim()) ||
        String(x?.refNo)?.toLowerCase().includes(search?.toLowerCase()?.trim())
    );
  }, [search, companies]);

  return (
    <div className="flex mt-10 flex-col">
      {createNewCompany ? (
        <>
          <AboutSection
            setCompanyDetails={() => {}}
            navigation={() => setCreateNewCompany(false)}
          />
          <AddContactSection />
        </>
      ) : (
        <>
          <span className="text-primary font-semibold text-sm text-center mb-14">
            Add company to this job requirement
          </span>

          <div className="gap-y-2 flex flex-1 flex-col min-w-[60%] self-center">
            <span
              className="text-[#00BB29] self-end font-medium text-xs px-2 py-1 rounded cursor-pointer"
              onClick={onCreateNewCompany}
            >
              Create new company contact
            </span>
            <div className="flex flex-1 border border-[#dddddd] rounded-lg gap-3 items-center justify-between px-3 ">
              <input
                className="bg-white flex flex-1 text-xs font-medium text-light-black outline-none px-2 py-3 rounded-lg"
                placeholder="Search by Name, Title or Ref number..."
                value={search}
                onChange={(e) => setSearch(e.target?.value)}
              />
              <SearchIcon className={"w-6 h-6"} fill={"#2e2e2e"} />
            </div>
            <div className="flex flex-1 flex-col border border-[#dddddd] rounded-lg gap-y-1 px-2.5 py-2 min-h-40 max-h-40 overflow-y-auto">
              {loading ? (
                <Spinner />
              ) : filterdCompanies?.length > 0 ? (
                filterdCompanies?.map((company) => (
                  <span
                    key={company?._id}
                    className="text-primary font-medium text-xs px-2 py-1 rounded hover:bg-[#f7f7f7] whitespace-pre cursor-pointer"
                    onClick={() => setValue("selectedCompany", company)}
                  >
                    {company?.name}
                    {"        "}
                    {company?.refNo}
                  </span>
                ))
              ) : (
                <div className="flex flex-1 h-screen flex-col items-center justify-center">
                  <p className="text-primary font-semibold">
                    No companies created
                  </p>
                </div>
              )}
            </div>
            <div className="flex flex-1 border border-[#dddddd] rounded-lg gap-3 items-center justify-between px-4 py-2.5">
              <span className="text-primary font-semibold text-xs text-center whitespace-pre">
                {fields?.selectedCompany
                  ? `${fields?.selectedCompany?.name}        ${fields?.selectedCompany?.refNo}`
                  : "Add company to this job requirement"}
              </span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Step1;
